export const texts = {
  continueWith: "continue with",
  facebook: "facebook",
  google: "google",
  twitter: "twitter",
  noAccount: "don't have an account?".toUpperCase(),
  haveAccount: "Already a member?",
  login: "login".toUpperCase(),
  continue: "continue".toUpperCase(),
  firstTimeLoginModalTitle: "Do you want to continue?",
  firstTimeLoginModalText:
    "Choose wisely—because once you get a name, you can’t change it.",
  signUp: "JOIN THE PARLIAMENT".toUpperCase(),
  agree: "I agree to {terms & conditions} of the site".toUpperCase(),
  termsConditions: "terms & conditions".toUpperCase(),
  email: "email",
  password: "password",
  confirmPassword: "confirm password".toUpperCase(),
  username: "username",
  generate: "generate",
  chooseUserName: "choose username",
  chooseUserNameText:
    "Other members of the community will identify you by your username.",
  FoundationSelect: 'For every vote you cast, Coin Parliament will match it and donate to one of The following foundations',
  ForgetPasswordText: `Don't remember your password ?`,
  UserNameValidation: 'User name should be unique and between 8 to 10 characters.',
  Total100: 'total share must be 100%',
  UserInfoUpdate: "user info was updated",
  UserFailUpdate: "user failed to be updated",
  success: "success",
  failed: "failed",
  PasswordResetLinkSent: "Password reset link has been sent to your email.",
  AgreetNc: "You must agree to t&c",
  PasswordUpdatSuccess: "Password updated successfully.",
  PasswordMustContain: "Password must contain at least 1 capital letter(s) (ABCDEFGHIJKLMNOPQRSTUVWXYZ). It must contain at least 1 numeric character(s) (0123456789). It must not contain more than 3 identical consecutive characters (AAA, iiii, $$$$$ ...). It must not contain your user name.",
  FASecurityAdded: "2FA security added to your account.",
  WrongCode: "Wrong code please try again.",
  CopiedClipboard: "copied to clipboard.",
  FeatureAvailableSoon: "Feature will be available soon",

  HereYourChance: "Here's your chance to VOTE, IMPACT & EARN!".toUpperCase(),
  Influencers: "Influencers".toUpperCase(),
  ToVote: "TO VOTE",
  VoteForYourWinner: "Vote for your winner",
  SelectTimeFrame: "select time frame",
  YouMustLoggedInVote: "you must be logged in to vote",
  Bear:"bear",
  Bull: "bull",
  InstallCoinParliament: "Install Stock Parliament app for best experience".toUpperCase(),
  Install: "Install".toUpperCase(),
  ViewAll: "view all".toUpperCase(),
  WhatYourCoinVote: "What's Your Coin Vote ?".toUpperCase(),
  WhatYourPairVote: "What's Your Pair Vote ?".toUpperCase(),
  Member: "member",
  Council: "council",
  Speaker: "speaker",
  Ambassador: "ambassador",
  Minister: "minister",
  SelectCollection: "Select Collection".toUpperCase(),
  SelectSets: "Select Sets".toUpperCase(),
  SelectType: "Select Type".toUpperCase(),
  Legendary: "Legendary",
  Rare: "Rare",
  Epic: "Epic",
  UNCommon: "UNCommon",
  Common: "Common",
  UpgradeYourAccount: "upgrade your account",
  CPMinting: "CP Minting".toUpperCase(),
  YourLevel: "Your Level".toUpperCase(),
  CoinParliamentBalance: "Coin Parliament Balance".toUpperCase(),
  CLAIMINGREWARDS:"CLAIMING REWARDS...",
  CLAIMYOURREWARDS: "CLAIM YOUR REWARDS",
  WEBELIEVEINPARTNERSHIPS: "WE BELIEVE IN PARTNERSHIPS!",
  friends: "friends".toUpperCase(),
  LoadingText: "loading...".toUpperCase(),
  Prev: "Prev".toUpperCase(),
  Next: "Next".toUpperCase(),
  Pair: "pair".toUpperCase(),
  Coin: "coin".toUpperCase(),
  AvailableCards: "Available cards".toUpperCase(),
  Followers: "followers".toUpperCase(),
  Following: "following".toUpperCase(),
  Privacy: "Privacy".toUpperCase(),
  USERNAME: "User Name".toUpperCase(),
  FIRSTNAME: "First Name".toUpperCase(),
  // LASTNAME: "Last Name",
  LASTNAME: "Last Name".toUpperCase(),
  EMAIL: "Email".toUpperCase(),
  BIO: "Bio".toUpperCase(),
  PHONE: "Phone".toUpperCase(),
  OLDPASSWORD: "Old Password".toUpperCase(),
  NEWPASSWORD: "New Password".toUpperCase(),
  AboutCoinParliament: "About Coin Parliament".toUpperCase(),
  GAMERULECOINPARLIAMENT: "GAME RULE COIN PARLIAMENT".toUpperCase(),
  REWARDHISTORY: "REWARD HISTORY",
  Votes: "Votes".toUpperCase(),
  GamePts: "Game Pts".toUpperCase(),
  Card: "Card".toUpperCase(),
  ForgetPassword:"Forget password",
  Foundations:"Foundations",
  Partners:"Partners",

  
};

export const urls = {
  termsConditions: "/terms-and-condition",
};
