/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import UserContext, { getUserInfo, saveDisplayName, saveUsername } from "./Contexts/User";
import FollowerContext, { getFollowerInfo } from "./Contexts/FollowersInfo";
import { texts } from './Components/LoginComponent/texts'
import { NotificationProps, UserProps } from "./common/models/User";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast, ToastContainer, Zoom } from "react-toastify";
import Home from "./Pages/Home";
import Profile, { ProfileTabs } from "./Pages/Profile";
import FollowerProfile, { FollowerProfileTabs } from "./Pages/FollowerProfile";
import Header from "./Components/Header";
import NotificationContext, { ToastType } from "./Contexts/Notification";
import CoinsContext, { Leader, Totals } from "./Contexts/CoinsContext";
import SingleCoin from "./Pages/SingleCoin";
import {
  Coin,
  DBCoin,
  DBPair,
  getAllCoins,
  getCoins,
  saveAllCoins,
  saveCoins,
} from "./common/models/Coin";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, functions, messaging } from "./firebase";
import Admin from "./Pages/Admin";
import { TimeFrame, VoteResultProps } from "./common/models/Vote";
import AppContext, {
  AppStats,
  CPMSettings,
  PaxData,
  VoteRules,
} from "./Contexts/AppContext";
import isNumber from "lodash/isNumber";
import {
  defaultUserType,
  UserTypeProps,
  validateTotalShare,
} from "./common/models/UserType";
import ManagersContext from "./Contexts/ManagersContext";
import CPMSettingsManager from "./managers/CPMSettingsManager";
import VoteRulesManager from "./managers/VoteRulesManager";
import TimeframesManager from "./managers/TimeframesManager";
import UserTypeManager from "./managers/UserTypeManager";
import CoinMain from "./Pages/CoinMain";
import firebase from "firebase/compat";
import PairsMain from "./Pages/PairsMain";
import SinglePair from "./Pages/SinglePair";
import { ENGLISH, translations } from "./common/models/Dictionary";
import { getKeyByLang, getLangByKey } from "./common/consts/languages";
import { getToken } from "firebase/messaging";
import { Form } from "react-bootstrap";
import { rest, ws } from "./common/models/Socket";
import { httpsCallable } from "firebase/functions";
import ContentContext, { ContentPage } from "./Contexts/ContentContext";
import Content from "./Pages/Content";
import LoginAndSignup from "./Components/LoginComponent/LoginAndSignup";
import {
  LoginAuthProvider,
  LoginRegular,
  Logout,
  SignupRegular,
} from "./common/models/Login";
import FirstTimeLogin from "./Components/LoginComponent/FirstTimeLogin";
import { generateUsername } from "./common/utils/strings";
import { intersection } from "lodash";
import Logo, { Size } from "./Components/Atoms/Logo";
import {
  AppContainer,
  getSubdomain,
  HomeContainer,
  isV1,
} from "./Components/App/App";
import Influencers from "./Pages/Influencers";
import NFTGallery from "./Pages/NFTGallery";
import NFTGalleryType from "./Pages/NFTGalleryType";
import Footer from "./Components/Footer";
import PersonalInfo from "./Components/Profile/PersonalInfo";
import Security from "./Components/Profile/Security";
import Mine from "./Components/Profile/Mine";
import Follow from "./Components/Profile/Follow";
import Pool from "./Components/Profile/Pool";
import { useWindowSize } from "./hooks/useWindowSize";
import Votes from "./Components/Profile/Votes";
import { ToastContent, ToastOptions } from "react-toastify/dist/types";
import FAQ from "./Pages/FAQ";
import { myPages, quotes } from "./common/consts/contents";
import Notifications from "./Components/Profile/Notifications";
import Background from "./Components/Background";
import Spinner from "./Components/Spinner";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
// import useScrollPosition from "./hooks/useScrollPosition";
import Button from "./Components/Atoms/Button/Button";
import FirstTimeAvatarSelection from "./Components/LoginComponent/FirstTimeAvatarSelection";
import FirstTimeFoundationSelection from "./Components/LoginComponent/FirstTimeFoundationSelection";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import UpgradePage from "./Components/Profile/UpgradePage";
import VotingBooster from "./Components/Profile/VotingBooster";
import ProfileNftGallery from "./Pages/ProfileNftGallery";
import GameRule from "./Pages/GameRule";
import ProfileNftGalleryType from "./Pages/ProfileNftGalleryType";
import SingalCard from "./Pages/SingalCard";
import FwMine from "./Components/FollowerProfile/FwMine";
import FwFollow from "./Components/FollowerProfile/FwFollow";
import FwVotes from "./Components/FollowerProfile/FwVotes";
import FwPool from "./Components/FollowerProfile/FwPool";
import Wallet from "./Components/Profile/Wallet";
import { pwaInstallHandler } from 'pwa-install-handler'
// import GoogleAuthenticator from "./Components/Profile/GoogleAuthenticator";
import Login2fa from "./Components/LoginComponent/Login2fa";
// import { handleSoundClick } from "./common/utils/SoundClick";
import TermsAndConditions from "./Pages/TermsAndConditions";
import CardShow from "./Components/Pairs/CardShow";
// import createFastContext from "./hooks/createFastContext";

const sendPassword = httpsCallable(functions, "sendPassword");
const localhost = window.location.hostname === "localhost";
// let userData=false
// const request = indexedDB.open("firebaseLocalStorageDb");
// request.onerror = (event) => {
//   console.error("Why didn't you allow my web app to use IndexedDB?!");
// };
// request.onsuccess = (event) => {
// //  @ts-ignore

//   const db = event?.target?.result
//   db
//   .transaction("firebaseLocalStorage")
//   .objectStore("firebaseLocalStorage").getAll().onsuccess = (event:any) => {
//     userData=event.target.result[0]?.value?.uid
//     console.log('Got all customers:', event.target.result[0]?.value?.uid);

//   }
// //   .get("444-44-4444").onsuccess = (event) => {
// //   console.log(`Name for SSN 444-44-4444 is ${event.target.result.name}`);
// // };
// };

function App() {
  const location = useLocation();
  const search = location.search;
  const pathname = location.pathname;
  const langDetector = useRef(null);
  let navigate = useNavigate();
  const { width } = useWindowSize();
  // const scrollPosition = useScrollPosition();
  const [modalOpen, setModalOpen] = useState(false);
  const [displayFullscreen, setDisplayFullscreen] = useState('none')

  // fullscreen mode
  useEffect(() => {
    const modal = document.getElementById("fullscreen-modal");
    window.addEventListener('load', () => {
      setDisplayFullscreen('block')
    });
  }, [])
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // @ts-ignore
  const fullscreenEnabled = document.fullscreenEnabled || document?.webkitFullscreenEnabled || document?.mozFullScreenEnabled || document?.msFullscreenEnabled;

  const handleClick = () => {

    setDisplayFullscreen('none')
    if (isMobile && fullscreenEnabled) {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
      // @ts-ignore
      else if (elem?.webkitRequestFullscreen) {
        // @ts-ignore
        elem?.webkitRequestFullscreen();
      }
      // @ts-ignore 
      else if (elem?.mozRequestFullScreen) {
        // @ts-ignore
        elem?.mozRequestFullScreen();
      }
      // @ts-ignore
      else if (elem?.msRequestFullscreen) {
        // @ts-ignore
        elem?.msRequestFullscreen();
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    // console.log("scrollTo");
  }, [pathname])

  const showToast = useCallback(
    (
      content: ToastContent,
      type?: ToastType,
      options: ToastOptions | undefined = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: "toast",
      }
    ) => {
      switch (type) {
        case ToastType.ERROR:
          toast.error(content, options);
          break;
        case ToastType.INFO:
          toast.info(content, options);
          break;
        default:
          toast.success(content, options);
      }
    },
    []
  );

  const showModal = useCallback(
    (
      content: ToastContent,
      options: ToastOptions | undefined = {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: true,
        draggable: false,
        progress: undefined,
        containerId: "modal",
        transition: Zoom,
        onOpen: () => setModalOpen(true),
        onClose: () => setModalOpen(false),
      }
    ) => {
      toast(content, options);
    },
    []
  );

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator?.serviceWorker?.addEventListener("message", (message) => {
        const {
          notification: { body, title },
        } = message.data["firebase-messaging-msg-data"] as {
          notification: { body: string; title: string };
        };

        // showToast(
        //   <div>
        //     <h5>{title}</h5>
        //     <p>{body}</p>
        //   </div>
        // );
      });
    }
  });
  useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    const classes = pathname
      .slice(1)
      .split("/")
      .filter((c) => c);
    body.classList.remove(...Array.from(body.classList));
    if (
      intersection(classes, ["coins", "pairs"]).length &&
      classes.length > 1
    ) {
      classes.push("single");
    }
    classes.forEach((c) => body.classList.add(c.toLowerCase()));
  }, [pathname]);
  const [allCoins, setAllCoins] = useState<string[]>(getAllCoins());
  const [changePrice, setChangePrice] = useState<any>(0);
  const [allPairs, setAllPairs] = useState<Array<string[]>>([]);
  const [appStats, setAppStats] = useState<AppStats>({} as AppStats);
  const [paxData, setPaxData] = useState<PaxData>({} as PaxData);
  const [authStateChanged, setAuthStateChanged] = useState(false);
  const [allButtonTime, setAllButtonTime] = useState<any>([]);
  const [allPariButtonTime, setAllPariButtonTime] = useState<any>([]);
  const [singalCardData, setSingalCardData] = useState<any>([]);
  const [nftAlbumData, setNftAlbumData] = useState<any>();
  const [forRun, setForRun] = useState<any>(0);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [pages, setPages] = useState<ContentPage[] | undefined>(myPages);
  const [coins, setCoins] = useState<{ [symbol: string]: Coin }>(
    getCoins() as { [symbol: string]: Coin }
  );
  const [loader, setLoader] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [user, setUser] = useState<User>();
  const [userInfo, setUserInfo] = useState<UserProps>();
  const [displayName, setDisplayName] = useState<string>("");
  const [leaders, setLeaders] = useState<Leader[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileTab, setProfileTab] = useState(ProfileTabs.profile);
  const [firstTimeAvatarSlection, setFirstTimeAvatarSelection] =
    useState(false);
  const [firstTimeFoundationSelection, setFirstTimeFoundationSelection] =
    useState(false);
  const [loginRedirectMessage, setLoginRedirectMessage] = useState("");
  const [userUid, setUserUid] = useState("");
  const [chosenUserType, setChosenUserType] = useState<string>("");
  const [lang, setLang] = useState<string>(
    getLangByKey(new URLSearchParams(search).get("lang") + "")
  );
  const [totals, setTotals] = useState<{ [key: string]: Totals }>(
    {} as { [key: string]: Totals }
  );
  const [timeframes, setTimeframes] = useState<TimeFrame[]>([]);
  const [voteRules, setVoteRules] = useState<VoteRules>({} as VoteRules);
  const [languages, setLanguages] = useState<string[]>([ENGLISH]);
  const [rtl, setRtl] = useState<string[]>([]);
  const [admin, setAdmin] = useState<boolean | undefined>(undefined);
  const [remainingTimer, setRemainingTimer] = useState(0)
  const [followerUserId, setFollowerUserId] = useState<string>('')
  const [withLoginV2e, setWithLoginV2e] = useState(false)
  const [CPMSettings, setCPMSettings] = useState<CPMSettings>(
    {} as CPMSettings
  );
  const [votesLast24Hours, setVotesLast24Hours] = useState<VoteResultProps[]>(
    []
  );
  const [userTypes, setUserTypes] = useState<UserTypeProps[]>([
    defaultUserType,
  ] as UserTypeProps[]);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [pwaPopUp, setPwaPopUp] = useState('block')
  const [mfaLogin, setMfaLogin] = useState(false)
  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);
  // @ts-ignore
  // useEffect(() => {
  //   const isMFAPassed = window.localStorage.getItem('mfa_passed')
  //   if (isMFAPassed == 'true' && !login) {

  //     console.log('2faCalled')
  //     // @ts-ignore
  //     Logout(setUser)
  //   }
  // }, [])


  const onClick = (evt: any) => {
    // evt.preventDefault();
    console.log('not supported', promptInstall)
    if (!promptInstall) {
      return;
    }
    // @ts-ignore
    else promptInstall.prompt();
  };
  if (!supportsPWA) {
    console.log('not supported')
  }
  useEffect(() => {
    if (user?.email && userInfo?.displayName === undefined && !login) {
      setLoader(true);
      //   .get("444-44-4444").onsuccess = (event) => {
      //   console.log(`Name for SSN 444-44-4444 is ${event.target.result.name}`);
      // };

      // setLoader(true);
    } else {
      // setTimeout(() => {
      setLoader(false);
      // }, 2000);
    }
  }, [user, userInfo]);
  const updateUser = useCallback(async (user?: User) => {
    setUser(user);

    const info = await getUserInfo(user);
    setUserInfo(info);
    setDisplayName(info.displayName + "");
  }, []);


  // const FollowerData = async(id:any) => {     
  //   const Followerinfo =  await getFollowerInfo(id);
  //   return Followerinfo
  // }

  // console.log(FollowerData("gK7iyJ8ysrSXQGKO4vch89WHPKh2"), "Followerinfo");


  useEffect(() => {
    if (user?.email && userInfo?.displayName === undefined && !login) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [user, userInfo]);
  useEffect(() => {
    // const buttons = document.getElementsByTagName('button');
    // console.log('buttondata',buttons);
    // for (let i = 0; i < buttons.length; i++) {
    //   buttons[i].addEventListener('click', handleSoundClick);
    // }

    const refer = new URLSearchParams(search).get("refer");
    if (refer && !user) {
      setLogin(false);
      setSignup(false);
    } else {
      const isMFAPassed = window.localStorage.getItem('mfa_passed')
      if (!user && isMFAPassed !== 'true') {
        console.log('2faCalled3')
        setLogin(false);
        setSignup(false);
      }

    }
    if (auth?.currentUser) {
      setLogin(false);
    }
  }, [location, search, JSON.stringify(auth?.currentUser)]);

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/", {replace: true});
  //   }
  // }, [user]);

  useEffect(() => {

    // @ts-ignore
    if ((user && userInfo && userInfo?.displayName === "" && userUid) || userInfo?.firstTimeLogin) {
      console.log("i am calling or not")
      setFirstTimeLogin(true);
    }

  }, [userInfo]);
  useEffect(() => {
    pwaInstallHandler.addListener((canInstall) => {

      console.log(canInstall, "canInstall")
      canInstall ? setPwaPopUp('block') : setPwaPopUp('none')
    })

  }, [])

  useEffect(() => {
    setMounted(true);
  }, [firstTimeLogin]);

  const [fcmToken, setFcmToken] = useState<string>("");
  const CPMSettingsMng = new CPMSettingsManager(CPMSettings, setCPMSettings);
  const VoteRulesMng = new VoteRulesManager(voteRules, setVoteRules);
  const TimeframesMng = new TimeframesManager(timeframes, setTimeframes);
  const UserTypeMng = new UserTypeManager(userTypes, setUserTypes);




  if (langDetector.current) {
    (langDetector?.current as unknown as HTMLInputElement)?.addEventListener(
      "change",
      (event) => {
        const target = event.target as HTMLInputElement;
        setLang(
          getLangByKey(new URLSearchParams(search).get("lang") || target.value)
        );
      }
    );
  }

  useEffect(() => {
    // getToken(messaging, {
    //   vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY,
    // }).then((token) => setFcmToken(token));
  }, []);
  console.log('fmctoken', fcmToken)
  useEffect(() => {
    const localStorageLang = localStorage.getItem("lang");
    if (localStorageLang && languages.includes(localStorageLang)) {
      setMounted(true);
    }
  }, [languages]);
  // console.log('user', userInfo)
  useEffect(() => {
    if (user?.uid) {
      setDoc(doc(db, "users", user?.uid), { lang }, { merge: true }).then(
        void 0
      );

      localStorage.setItem("lang", lang);
    }
  }, [lang, user?.uid]);

  // const isAdmin = useCallback(async (uid?: string) => {
  //   if (uid) {
  //     const func = httpsCallable(functions, "isAdmin");
  //     return !!(await func({ user: uid })).data;
  //   }
  // }, []);

  // useEffect(() => {
  //   isAdmin(user?.uid).then((newAdmin) => setAdmin(newAdmin));
  // }, [user?.uid, isAdmin]);

  // useEffect(() => {
  //   onSnapshot(doc(db, "stats", "leaders"), (doc) => {
  //     setLeaders((doc.data() as { leaders: Leader[] })?.leaders || []);

  //   });

  //   onSnapshot(
  //     query(
  //       collection(db, "notifications"),
  //       where("user", "==", user?.uid || "")
  //     ),
  //     (querySnapshot) => {
  //       setNotifications(
  //         querySnapshot.docs.map((doc) => {
  //           return doc.data() as NotificationProps;
  //         })
  //       );
  //     }
  //   );

  //   onSnapshot(doc(db, "stats", "totals"), (doc) => {
  //     setTotals((doc.data() || {}) as { [key: string]: Totals });
  //   });

  //   onSnapshot(doc(db, "settings", "timeframes"), (doc) => {
  //     setTimeframes(
  //       ((doc.data() as { timeframes: TimeFrame[] }) || {}).timeframes || []
  //     );
  //   });

  //   onSnapshot(doc(db, "settings", "userTypes"), (doc) => {
  //     const u = (
  //       (doc.data() || {
  //         userTypes: [defaultUserType],
  //       }) as { userTypes: UserTypeProps[] }
  //     ).userTypes;

  //     setUserTypes(u);
  //   });

  //   onSnapshot(doc(db, "settings", "settings"), (doc) => {
  //     setVoteRules(
  //       ((doc.data() || {}) as { voteRules: VoteRules }).voteRules || {}
  //     );
  //     setCPMSettings(
  //       ((doc.data() || {}) as { CPMSettings: CPMSettings }).CPMSettings || {}
  //     );
  //   });

  //   onSnapshot(doc(db, "settings", "languages"), (doc) => {
  //     const u = (
  //       (doc.data() || {
  //         languages: [ENGLISH],
  //       }) as { languages: string[] }
  //     ).languages;

  //     setLanguages(u);
  //   });

  //   onSnapshot(doc(db, "settings", "rtl"), (doc) => {
  //     const r = (
  //       (doc.data() || {
  //         rtl: [],
  //       }) as { rtl: string[] }
  //     ).rtl;

  //     setRtl(r);
  //   });

  //   onSnapshot(collection(db, "translations"), (querySnapshot) => {
  //     querySnapshot.docs.forEach((doc) => {
  //       translations.set(doc.id, doc.data());
  //     });
  //   });

  //   onSnapshot(doc(db, "stats", "coins"), (doc) => {

  //     const newAllCoins = (doc.data() as { [key: string]: Coin }) || {};
  //     setCoins(newAllCoins);
  //     // saveCoins(newAllCoins);
  //   });

  //   onSnapshot(doc(db, "stats", "app"), (doc) => {
  //     setAppStats(doc.data() as AppStats);
  //   });

  //   onSnapshot(doc(db, "settings", "paxData"), (doc) => {
  //     setPaxData(doc.data() as PaxData);
  //   });

  //   onSnapshot(doc(db, "settings", "coins"), (doc) => {
  //     const newAllCoins = (
  //       ((doc.data() as { coins: DBCoin[] }) || {}).coins || []
  //     )
  //       .sort((a, b) => Number(a.id) - Number(b.id))
  //       .map((c) => c.symbol);

  //     saveAllCoins(newAllCoins);
  //     setAllCoins(newAllCoins);
  //   });

  //   onSnapshot(doc(db, "settings", "pairs"), (doc) => {
  //     setAllPairs(
  //       (((doc.data() as { pairs: DBPair[] }) || {}).pairs || [])
  //         .sort((a, b) => Number(a.id) - Number(b.id))
  //         .map((p) => {
  //           return [p.symbol1, p.symbol2];
  //         })
  //     );
  //   });
  // }, [user?.uid]);

  useEffect(() => {
    const auth = getAuth();

    if (!firstTimeLogin) {
      onAuthStateChanged(auth, async (user: User | null) => {
        setAuthStateChanged(true);
        if (
          user?.emailVerified ||
          user?.providerData[0]?.providerId === "facebook.com"
        ) {
          // setLogin(false);
          // setSignup(false);
          setLoginRedirectMessage("");
          await updateUser(user);
          setUserUid(user?.uid);
          onSnapshot(doc(db, "users", user.uid), async (doc) => {
            await setUserInfo(doc.data() as UserProps);
            setDisplayName((doc.data() as UserProps).displayName + "");
          });
          // const votesLast24HoursRef = firebase
          //   .firestore()
          //   .collection("votes")
          //   .where("userId", "==", user.uid)
          //   .where("voteTime", ">=", Date.now() - 24 * 60 * 60 * 1000)
          //   .where("voteTime", "<=", Date.now());
          //   console.log('extravote11',votesLast24HoursRef)
          //   await votesLast24HoursRef.onSnapshot((snapshot) => {
          //     console.log('extravote1')
          //     setVotesLast24Hours(
          //       snapshot.docs.map((doc) => doc.data() as unknown as VoteResultProps),
          //     );
          //   });


          try {
            if (fcmToken) {
              try {
                await setDoc(
                  doc(db, "users", user.uid),
                  { token: fcmToken },
                  { merge: true }
                );
                console.log("push enabled");
              } catch (e) {
                console.log(e);
              }
            }
          } catch (e) {
            console.log("An error occurred while retrieving token. ", e);
          }
        } else {
          await updateUser();
        }
      });
    }
  }, [user, fcmToken, coins]);

  // useEffect(() => {

  //   if(user?.uid){

  //   const currentTime = firebase.firestore.Timestamp.fromDate(new Date());
  // // const last24Hour = currentTime.toMillis() - 24 * 60 * 60 * 1000;
  // const last24Hour = currentTime.toMillis() - voteRules.timeLimit * 1000;

  // const votesLast24HoursRef = firebase
  //             .firestore()
  //             .collection("votes")
  //             .where("userId", "==", user?.uid)
  //             .where("voteTime", ">=", last24Hour)
  //             .where("voteTime", "<=", Date.now());
  // // console.log('extravote11',votesLast24HoursRef)
  // votesLast24HoursRef.get()
  //     .then((snapshot) => {
  //         setVotesLast24Hours(snapshot.docs.map((doc) => doc.data() as unknown as VoteResultProps));

  //         const data = snapshot.docs.map((doc) => doc.data() as unknown as VoteResultProps)
  //       let remaining= (Math.min(...data.map((v) => v.voteTime)) + voteRules.timeLimit * 1000) -  Date.now();

  //   setRemainingTimer((Math.min(...data.map((v) => v.voteTime)) + voteRules.timeLimit * 1000))

  //   setTimeout(() => {
  //     if(user?.uid){

  //       const currentTime = firebase.firestore.Timestamp.fromDate(new Date());
  //     // const last24Hour = currentTime.toMillis() - 24 * 60 * 60 * 1000;
  //     const last24Hour = currentTime.toMillis() - voteRules.timeLimit * 1000;

  //     const votesLast24HoursRef = firebase
  //                 .firestore()
  //                 .collection("votes")
  //                 .where("userId", "==", user?.uid)
  //                 .where("voteTime", ">=", last24Hour)
  //                 .where("voteTime", "<=", Date.now());
  //     // console.log('extravote11',votesLast24HoursRef)
  //     votesLast24HoursRef.get()
  //         .then((snapshot) => {
  //             setVotesLast24Hours(snapshot.docs.map((doc) => doc.data() as unknown as VoteResultProps));

  //         })
  //         .catch((error) => {
  //             // console.log('extravoteError',error);
  //         });
  //       }
  //   }, remaining);
  //     })
  //     .catch((error) => {
  //         // console.log('extravoteError',error);
  //     });
  //   }


  // }, [userInfo?.voteStatistics?.total])


  useEffect(() => {
    const html = document.querySelector("html") as HTMLElement;
    const key = getKeyByLang(lang);
    html.setAttribute("lang", key);
    if (rtl.includes(lang)) {
      html.setAttribute("dir", "rtl");
    } else {
      html.removeAttribute("dir");
    }
  }, [lang, rtl, user?.uid]);

  const [enabled, enable] = useState(true);
  const [password, setPassword] = useState("");

  // useEffect(() => {
  //   async function removeData() {
  //     const voteData = await firebase.firestore().collection('votes').where("userId", "==", "gK7iyJ8ysrSXQGKO4vch89WHPKh2").get();
  //     const batch = firebase.firestore().batch();
  //     voteData.forEach(doc => {
  //       batch.delete(doc.ref);
  //     });
  //     await batch.commit();

  //     console.log("User vote data deleted");
  //   }
  //   removeData()
  // }, [])

  // login user using token
  const [searchParams] = useSearchParams();
  useEffect(() => {
    let token = searchParams.get('token');
    if (token) {
      firebase.auth().signInWithCustomToken(token)
        .then((userCredential) => {
          // User is signed in
          const user = userCredential.user;
          console.log('Custom token sign-in success: authenticated', user?.emailVerified);
          navigate('/');
        })
        .catch((error) => {
          // Handle sign-in errors
          console.error('Custom token sign-in error: authenticated', error);
        });
    }
  }, [searchParams]);

  // useEffect(() => {
  //   if (auth?.currentUser && !auth?.currentUser?.emailVerified) {
  //     auth.signOut();
  //     showToast("Please verify your email address.", ToastType.ERROR);
  //   }    
  // }, [JSON.stringify(auth?.currentUser)]);
  // console.log(auth?.currentUser,"checkauth")




  return loader ? (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ height: "100vh", width: "100vw", color: "white" }}
    >
      <Spinner />
    </div>
  ) : (
    <div>
      {enabled && (
        <NotificationContext.Provider
          value={{
            showToast,
            showModal,
          }}
        >
          <Form.Control
            type='hidden'
            id='lang-detector'
            ref={langDetector}
            onChange={(e) => console.log(e.target.value)}
          />
          <ManagersContext.Provider
            value={{
              CPMSettingsMng,
              VoteRulesMng,
              TimeframesMng,
              UserTypeMng,
            }}
          >
            <AppContext.Provider
              value={{
                withLoginV2e,
                setWithLoginV2e,
                followerUserId,
                setFollowerUserId,
                singalCardData,
                setSingalCardData,
                remainingTimer,
                setNftAlbumData,
                nftAlbumData,
                setAllPariButtonTime,
                allPariButtonTime,
                allButtonTime,
                forRun,
                setForRun,
                setAllButtonTime,
                chosenUserType,
                setChosenUserType,
                setLoginRedirectMessage,
                loginRedirectMessage,
                paxData,
                setPaxData,
                appStats,
                setAppStats,
                authStateChanged,
                login,
                setLogin,
                profileTab,
                setProfileTab,
                signup,
                setSignup,
                firstTimeLogin,
                setFirstTimeLogin,
                menuOpen,
                setMenuOpen,
                fcmToken,
                setFcmToken,
                lang,
                setLang,
                rtl,
                setRtl,
                languages,
                setLanguages,
                translations,
                setTranslations: (newTranslations) => {
                  newTranslations = new Map(newTranslations);
                  translations.clear();
                  newTranslations.forEach(async (value, key) => {
                    translations.set(key, value);
                    await setDoc(doc(db, "translations", key), value);
                  });
                },
                userTypes,
                setUserTypes: async (u: UserTypeProps[]) => {
                  u = UserTypeMng.setter(u);
                  if (validateTotalShare(u)) {
                    await setDoc(
                      doc(db, "settings", "userTypes"),
                      {
                        userTypes: u,
                      },
                      { merge: true }
                    );
                  } else {
                    if (u.every((uu) => uu.share)) {
                      showToast(texts.Total100, ToastType.ERROR);
                    }
                  }
                },
                CPMSettings,
                setCPMSettings: async (settings: CPMSettings) => {
                  settings = CPMSettingsMng.setter(settings);
                  await setDoc(
                    doc(db, "settings", "settings"),
                    {
                      voteRules: voteRules || {},
                      CPMSettings: settings,
                    },
                    { merge: true }
                  );
                },
                voteRules,
                setVoteRules: async (rules: VoteRules) => {
                  rules = VoteRulesMng.setter(rules);
                  await setDoc(
                    doc(db, "settings", "settings"),
                    {
                      voteRules: rules,
                      CPMSettings: CPMSettings || {},
                    },
                    { merge: true }
                  );
                },
                timeframes,
                setTimeframes: async (t: TimeFrame[]) => {
                  t = TimeframesMng.setter(t);
                  await setDoc(
                    doc(db, "settings", "timeframes"),
                    {
                      timeframes: t.filter(
                        (tt) => isNumber(tt.index) && tt.seconds && tt.name
                      ),
                    },
                    { merge: true }
                  );
                },
              }}
            >
              <ContentContext.Provider
                value={{
                  pages,
                  setPages,
                  quotes,
                }}
              >
                <CoinsContext.Provider
                  value={{
                    changePrice,
                    setChangePrice,
                    ws,
                    rest,
                    coins,
                    setCoins,
                    leaders,
                    setLeaders,
                    totals,
                    setTotals,
                    allCoins,
                    allPairs,
                  }}
                >
                  <UserContext.Provider
                    value={{
                      notifications,
                      setNotifications,
                      admin,
                      setAdmin,
                      user,
                      userInfo,
                      displayName,
                      setDisplayName,
                      setUser: updateUser,
                      setUserInfo,
                      votesLast24Hours,
                      setVotesLast24Hours,
                    }}
                  >
                    {getSubdomain() === "admin" && user && <Admin />}
                    {(getSubdomain() !== "admin" ||
                      (getSubdomain() === "admin" && !user)) && (
                        <>

                          <Background pathname={pathname} />
                          <AppContainer
                            fluid
                            pathname={pathname}
                            login={login || firstTimeLogin ? "true" : "false"}
                          // width={width}
                          >

                            <Header
                              remainingTimer={remainingTimer}
                              logo={
                                (login && window.screen.width > 979) ||
                                window.screen.width > 979
                              }
                              pathname={pathname}
                              title={
                                // pathname !== "/" && !login ? (
                                //   <H1 style={{color: "var(--white)"}}>
                                //     {pathname !== "/login" && pathname !== "/signup" && fixTitle(
                                //       getTitle(
                                //         pages?.find((p) => p.slug === pathname.slice(1))
                                //           ?.title || pathname.slice(1),
                                //         lang,
                                //       ),
                                //       pathname,
                                //     )}
                                //   </H1>
                                // ) : (
                                <HomeContainer
                                  className='d-flex flex-column justify-content-center align-items-center p-0'
                                  width={width}
                                >
                                  <div
                                    className='mb-2 d-flex align-items-center'
                                    style={{
                                      flexFlow:
                                        width && width > 979 ? "row" : "column",
                                      justifyContent:
                                        width && width > 979
                                          ? "center"
                                          : "center",
                                      width: width && width > 979 ? 233 : "auto",
                                    }}
                                  >
                                    <Link to={"/"}>
                                      {window.screen.width < 979 && (
                                        <Logo
                                          size={
                                            width && width > 979
                                              ? Size.XSMALL
                                              : Size.XSMALL
                                          }
                                        />
                                      )}
                                      {/* {scrollPosition >= positionBreakpoint && window.screen.width<979 &&<Logo
                                        size={Size.XSMALL}
                                      />} */}
                                    </Link>
                                    {
                                      // ((scrollPosition < positionBreakpoint) && (width && width < 979)) && <H1
                                      //   desktop={
                                      //     width && width > 979 ? "true" : "false"
                                      //   }
                                      //   className="mt-2"
                                      //   onClick={() => navigate("/", {replace: true})}
                                      // >
                                      //   {/* {!login && !firstTimeFoundationSelection && !firstTimeLogin &&!firstTimeAvatarSlection? capitalize(translate("coin parliament", lang)): null} */}
                                      // </H1>
                                    }
                                  </div>
                                </HomeContainer>
                              }
                            />

                            {user && firstTimeLogin && (
                              <FirstTimeLogin
                                setFirstTimeAvatarSelection={
                                  setFirstTimeAvatarSelection
                                }
                                generate={generateUsername}
                                saveUsername={async (username: any, DisplayName: any) => {
                                  if (user?.uid) {
                                    await saveUsername(user?.uid, username, "");
                                    await saveDisplayName(user?.uid, DisplayName, "");
                                    setFirstTimeAvatarSelection(true);
                                    // setFirstTimeFoundationSelection(true);
                                    setFirstTimeLogin(false);
                                  }
                                }}
                              />
                            )}

                            {!firstTimeLogin && firstTimeAvatarSlection && (
                              <FirstTimeAvatarSelection
                                user={user}
                                setFirstTimeAvatarSelection={
                                  setFirstTimeAvatarSelection
                                }
                              />
                            )}
                            {/* {!firstTimeAvatarSlection &&
                            firstTimeFoundationSelection && (
                              <FirstTimeFoundationSelection
                                user={user}
                                setFirstTimeFoundationSelection={
                                  setFirstTimeFoundationSelection
                                }
                              />
                            )} */}
                            {!firstTimeLogin && (
                              <>
                                {!user && login && !mfaLogin && (
                                  <LoginAndSignup
                                    {...{
                                      authProvider: LoginAuthProvider,
                                      loginAction: LoginRegular,
                                      signupAction: SignupRegular,
                                    }}
                                  />
                                )}
                                {(user || userInfo?.uid) && localStorage.getItem('mfa_passed') === 'true' && (
                                  <Login2fa
                                    setLogin={setLogin}
                                    setMfaLogin={setMfaLogin}
                                  />
                                )}
                                {(!login && 
                                  !firstTimeAvatarSlection &&
                                  !firstTimeFoundationSelection && localStorage.getItem('mfa_passed') !== 'true') && (
                                    <>
                                      <Container
                                        fluid
                                        style={{
                                          background:
                                            pathname == "/" ? "#160133" : "",
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                          minHeight:
                                            window.screen.width < 979
                                              ? "89vh"
                                              : "92vh",
                                          // padding: `${pathname === "/" ? 120 : 84}px 0 109px`,
                                          padding: `${pathname === "/" ? 120 : 120
                                            }px 0 0`,
                                        }}
                                      >
                                        <div className='pwaPopup' style={{ display: pwaPopUp }}>
                                          <span>{texts.InstallCoinParliament}</span>
                                          <button
                                            className="link-button"
                                            id="setup_button"
                                            aria-label="Install app"
                                            title="Install app"
                                            onClick={onClick}
                                            style={{ zIndex: 99999 }}
                                          >
                                            Install
                                          </button>
                                          <span
                                            className="link-button"
                                            id="setup_button"
                                            aria-label="Install app"
                                            title="Install app"
                                            onClick={e => setPwaPopUp('none')}
                                            style={{ zIndex: 99999, position: 'absolute', top: '5px', right: '10px', fontSize: '18px', cursor: "pointer" }}
                                          >
                                            x
                                          </span>
                                        </div>
                                        <Routes>


                                          <Route path='/' element={<Home />} />
                                          <Route
                                            path='CardShow/:id'
                                            element={<CardShow />}
                                          />

                                          {/* <Route
                                          path='coins'
                                          element={<CoinMain />}
                                        />
                                        <Route
                                          path='nftAlbum'
                                          element={<NFTGallery />}
                                        />
                                        <Route
                                          path='nftAlbum/:type'
                                          element={<NFTGalleryType />}
                                        />
                                        <Route
                                          path='singalCard/:type/:id'
                                          element={<SingalCard />}
                                        />
                                        <Route
                                          path='coins/:id'
                                          element={<SingleCoin />}
                                        />
                                        <Route
                                          path='pairs'
                                          element={<PairsMain />}
                                        />
                                        <Route
                                          path='pairs/:id'
                                          element={<SinglePair />}
                                        />
                                        <Route
                                          path={ProfileTabs.profile}
                                          element={<Profile />}
                                        >
                                          // <Route
                                          //   path={ProfileTabs.edit}
                                          //   element={<PersonalInfo />}
                                          // />
                                          // <Route
                                          //   path={ProfileTabs.password}
                                          //   element={<Security />}
                                          // />
                                          {!isV1() && (
                                            <Route
                                              path={ProfileTabs.mine}
                                              element={<Mine />}
                                            />
                                          )}
                                          <Route
                                            path={ProfileTabs.followers}
                                            element={<Follow />}
                                          />
                                          <Route
                                            path={ProfileTabs.votes}
                                            element={<Votes />}
                                          />
                                          <Route
                                              path={ProfileTabs.share}
                                              element={<Pool />}
                                          />
                                    

                                          <Route
                                            path={ProfileTabs.notifications}
                                            element={<Notifications />}
                                          />
                                          <Route
                                            path={
                                              ProfileTabs.ProfileNftGallery
                                            }
                                            element={<ProfileNftGallery />}
                                          />
                                          <Route
                                            path={
                                              ProfileTabs.ProfileNftGalleryType
                                            }
                                            element={<ProfileNftGalleryType />}
                                          />
                                        </Route> 
                                        */}

                                          <Route
                                            path={ProfileTabs.profile}
                                            element={<Profile />}
                                          >

                                            <Route
                                              path={ProfileTabs.share}
                                              element={<Pool />}
                                            />
                                            <Route
                                              path={ProfileTabs.edit}
                                              element={<PersonalInfo />}
                                            />
                                            <Route
                                              path={ProfileTabs.password}
                                              element={<Security />}
                                            />
                                            <Route
                                              path={
                                                ProfileTabs.wallet
                                              }
                                              element={<Wallet />}
                                            />
                                          </Route>
                                          {/* Fowller component  start*/}
                                          {/* <Route
                                          path={FollowerProfileTabs.FollowerProfile}
                                          element={<FollowerProfile />}
                                        >
                                          {!isV1() && (
                                            <Route
                                              path={FollowerProfileTabs.mine}
                                              element={<FwMine />}
                                            />
                                          )}
                                          
                                           <Route
                                            path={FollowerProfileTabs.followers}
                                            element={<FwFollow />}
                                          />
                                          <Route
                                            path={FollowerProfileTabs.votes}
                                            element={<FwVotes />}
                                          />
                                          <Route
                                              path={FollowerProfileTabs.share}
                                              element={<FwPool />}
                                          />
                                          </Route> */}

                                          {/* Fowller component  end*/}
                                          {/* <Route
                                          path='/upgrade'
                                          element={<UpgradePage />}
                                        />
                                        <Route
                                          path='/votingbooster'
                                          element={<VotingBooster />}
                                        />
                                        <Route
                                          path='influencers'
                                          element={<Influencers />}
                                        /> */}

                                          {/* <Route path="signup" element={<LoginAndSignup/>}/> */}
                                          {/* <Route path='faq' element={<FAQ />} />
                                        <Route
                                          path='about'
                                          element={<About />}
                                        />
                                        <Route
                                          path='gamerule'
                                          element={<GameRule />}
                                        />
                                        <Route
                                          path='contact'
                                          element={<Contact />}
                                        />
                                        <Route
                                          path='privacy'
                                          element={<PrivacyPolicy />}
                                        /> */}
                                          <Route
                                            path='privacy'
                                            element={<PrivacyPolicy />}
                                          />
                                          <Route
                                            path='/terms-and-condition'
                                            element={<TermsAndConditions />}
                                          />
                                          {localhost && user && (
                                            <Route
                                              path='admin'
                                              element={<Admin />}
                                            />
                                          )}
                                          {pages
                                            ?.filter((p) => p.title !== "x")
                                            .map((page, u) => (
                                              <Route
                                                key={u}
                                                path={page.slug}
                                                element={<Content />}
                                              />
                                            ))}
                                          <Route path='*' element={<Content />} />
                                        </Routes>
                                      </Container>
                                      <Footer />
                                    </>
                                  )}
                              </>
                            )}
                          </AppContainer>
                        </>
                      )}
                    <ToastContainer enableMultiContainer containerId='toast' />
                    <ToastContainer enableMultiContainer containerId='modal' />
                    {modalOpen && <div className='fade modal-backdrop show' />}
                  </UserContext.Provider>
                </CoinsContext.Provider>
              </ContentContext.Provider>
            </AppContext.Provider>
          </ManagersContext.Provider>
        </NotificationContext.Provider>
      )}
      {!enabled && (
        <Container>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const resp = await sendPassword({ password });
              console.log(resp.data);
              if (resp.data === true) {
                enable(true);
              }
            }}
          >
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Password'
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button type='submit'>Submit</Button>
          </Form>
        </Container>
      )}
    </div>
  );
}

export default App;

export const showToast = (
  content: ToastContent,
  type?: ToastType,
  options: ToastOptions | undefined = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    containerId: "toast",
  }
) => {
  toast.dismiss();
  toast.clearWaitingQueue();
  switch (type) {
    case ToastType.ERROR:
      toast.error(content, options);
      break;
    case ToastType.INFO:
      toast.info(content, options);
      break;
    default:
      toast.success(content, options);
  }
};
