import React from "react";

const AddFollower = () => {
  return (
    <svg
      id="add_follow"
      data-name="add follow"
      xmlns="http://www.w3.org/2000/svg"
      width="16.81"
      height="15.689"
      viewBox="0 0 16.81 15.689"
    >
      <path
        id="Path_3501"
        data-name="Path 3501"
        d="M9.547,3.371a2.8,2.8,0,1,1-2.8,2.8,2.8,2.8,0,0,1,2.8-2.8m0-1.121A3.922,3.922,0,1,0,13.47,6.172,3.922,3.922,0,0,0,9.547,2.25Z"
        transform="translate(-3.944 -2.25)"
        fill="#6352e8"
      />
      <path
        id="Path_3502"
        data-name="Path 3502"
        d="M13.457,26.974H12.336v-2.8a2.8,2.8,0,0,0-2.8-2.8H6.172a2.8,2.8,0,0,0-2.8,2.8v2.8H2.25v-2.8A3.922,3.922,0,0,1,6.172,20.25H9.534a3.922,3.922,0,0,1,3.922,3.922Z"
        transform="translate(-2.25 -11.285)"
        fill="#6352e8"
      />
      <path
        id="Path_3503"
        data-name="Path 3503"
        d="M30.353,13.491H28.112V11.25H26.991v2.241H24.75v1.121h2.241v2.241h1.121V14.612h2.241Z"
        transform="translate(-13.543 -5.767)"
        fill="#6352e8"
      />
    </svg>
  );
};

export default AddFollower;
